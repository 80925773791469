.busItem {
  width: 80%;
  color: white;
  border-radius: 5px;
  padding: 20px 0;
  background-color: var(--dirty-black);
}

.busItemLine {
  color: var(--bus-orange);
  font-weight: 700;
  text-transform: uppercase;

  border-radius: var(--border-radius);
  width: 100%;
  margin: 0 0 10px 0;
}
