.stopItem {
  background-color: var(--bus-yellow);
  width: 80%;
  text-align: center;
  border-radius: var(--border-radius);
}

.stopItem:hover {
  cursor: pointer;
  box-shadow: 2px 5px 10px -2px var(--dirty-black);
}

.stopItemLogo {
  /* flip horizontally */
  transform: scaleX(-1);
  max-width: 50px;
  padding: 5px 0 0 0;
}
.stopName,
.stopShortName {
  background-color: var(--dirty-black);
  color: white;
}
.stopName {
  padding-top: 5px;
  text-transform: uppercase;

  font-size: var(--font-size-regular);
}
.stopShortName {
  padding-bottom: 5px;
  font-size: var(--font-size-small);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
