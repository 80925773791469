.button {
  background-color: white;
  width: 90%;
  text-align: center;
  border: 1px solid var(--nysse-blue);
  border-radius: var(--border-radius);
  padding: 20px 0;
}

.button:hover {
  cursor: pointer;
  color: var(--nysse-blue);
  background-color: var(--nysse-dark-blue);
  color: white;
}
