.stopSearch {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.searchSection {
  width: 90%;
}
.searchDescription {
  color: var(--dark-grey);
  margin-bottom: 20px;
}

.searchInput {
  color: var(--dark-grey);
  text-align: center;
  width: 100%;
  height: 40px;
  font-size: var(--font-size-regular);
  box-sizing: border-box;
  border: 1px solid black;
  padding: 0 10px 0 10px;
  border-radius: var(--border-radius);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--light-grey);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--light-grey);
}

.stopList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

/* TODO: Tämä on vanhasta! */
.stopContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (max-width: 900px) {
  .stopSearch {
    width: 100vw;
  }
}
