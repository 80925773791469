/* Colors, sizevariables etc all live here and are available globally */

:root {
  --bus-yellow: #ffda0f;
  --bus-orange: rgb(255, 168, 6);
  --nysse-blue: #1c57cf;
  --nysse-dark-blue: #1a4a8f;
  --grey: #aab2c2;
  --dark-grey: #6c7484;
  --light-grey: #cfd8e8;
  --dirty-black: #2c2c2a;

  --font-size-regular: 1rem;
  --font-size-small: 0.75rem;
  --font-size-extra-small: 0.625rem;
  --font-size-large: 1.25rem;

  --border-radius: 4px;

  --breakpoint-narrow-desktop: 900px;
}
