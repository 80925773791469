@import url("https://fonts.googleapis.com/css2?family=Inria+Sans:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Inria Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:link,
a:visited {
  text-decoration: none;
  color: var(--nysse-blue);
}

a:hover,
a:active {
  cursor: pointer;
  color: var(--nysse-dark-blue);
  text-decoration: underline;
  font-weight: 700;
}
